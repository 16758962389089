import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {

  constructor() { }
  private current = 0;
  private opened = true;
  private left = 0.7; // init left of anime div
  private width = 3;  // init witdh of anime div
  private itemWidth = 4.3984375;

  changePage(tarrget: number) {
    const animatedDiv = (document.querySelector('.animated-div') as HTMLElement);
    const distance = Math.abs(tarrget - this.current);

    const keyFrems = [
      { width: `${this.width}rem`, left: `${this.itemWidth * this.current + this.left}rem`, offset: 0 },
      {
        width: `${this.width * (distance + 1) + 1.4 * distance}rem`,
        left: `${this.itemWidth * (tarrget > this.current ? this.current : tarrget) + this.left}rem`,
        offset: 0.5,
      },
      { width: `${this.width}rem`, left: `${this.itemWidth * tarrget + this.left}rem`, offset: 1 }
    ];

    animatedDiv.animate(keyFrems, {
      duration: distance === 1 ? 300 : 500,
      easing: 'ease-in-out',
      fill: 'forwards'
    });

    this.current = tarrget;
  }

  fade() {
    const opened = this.opened;
    console.log(opened);

    const elements = Array.from(document.querySelectorAll('.fadeOut'));
    elements.forEach((el) => el.animate(this.keyFrame(opened), this.animateProperty()));

    this.opened = !this.opened;
  }

  private keyFrame(open) {
    return open ? [{ opacity: '1' }, { opacity: '0' }] : [{ opacity: '0' }, { opacity: '1' }];
  }

  private animateProperty() {
    return { duration: 300, easing: 'ease-in-out', fill: 'forwards' } as KeyframeAnimationOptions;
  }
}
