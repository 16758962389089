import { Component, OnInit, Output, EventEmitter, HostBinding, AfterViewInit } from '@angular/core';
import { HttpService } from '../http.service';
import { AnimationsService } from './animations.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map, pairwise, share, throttleTime } from 'rxjs/operators';
// import { disableBodyScroll } from 'body-scroll-lock';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => *', animate('500ms ease-in'))
    ])
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {

  constructor(public animeService: AnimationsService, private httpService: HttpService) { }

  @Output() sidenavToggle = new EventEmitter<void>();
  subMenuData = [];
  private isVisible = true;
  private targetElement = null;
  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }
  private direction: string;
  placeholder = 'Pretraži Moja radnja';

  ngOnInit() {
    this.httpService.getData().subscribe((res: any) => {
      this.subMenuData = res.subMenuData;
    });
  }
  onToggleSidenav() {
    this.sidenavToggle.emit();
  }
  ngAfterViewInit() {
    // this.targetElement = document.querySelector('.sub-nav');
    // disableBodyScroll(this.targetElement);

    const content = document.querySelector('.mat-sidenav-content');
    const scroll = fromEvent(content, 'scroll').pipe(
      throttleTime(100),
      map(() => content.scrollTop),
    );

    const scrollDirection = scroll.pipe(
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    scrollDirection.subscribe((direction) => {
      this.direction = direction;
    });

    scroll.subscribe((position) => {
      if (position > 30) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
      if (this.direction === 'Up') {
        this.isVisible = true;
        if ((position + content.clientHeight) > (content.scrollHeight - 300)) {
          this.isVisible = false;
        }
      }
    });
  }
  animate() {
    this.placeholder = '';
    this.animeService.fade();
  }
}
