import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Output() closeSidenav = new EventEmitter<void>();

  constructor(private httpService: HttpService) { }

  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  ngOnInit() {
    this.httpService.getData().subscribe((res: any) => {
      this.dataSource.data = res.treeData;
    });

  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  onClose() {
    this.closeSidenav.emit();
  }
}
