import { Component, OnInit, AfterViewInit } from '@angular/core';
import { disableBodyScroll } from 'body-scroll-lock';
import { HttpService } from '../http.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit, AfterViewInit {

  constructor(private httpService: HttpService) { }
  targetElement = null;

  products = [];
  backgroundColor = ['#8CE6DD', '#FECBD5', '#FEF0CB', '#C6F0FF', '#D39DF6', '#DFDAE2', '#FEB4AB', '#B1ECDC', '#FFE19C', '#9DC98A'];
  ngOnInit() {
    this.httpService.getData().subscribe((res: any) => {
      this.products = res.products;
    });
  }

  ngAfterViewInit(): void {
    this.targetElement = document.querySelector('.mat-sidenav-content');
    disableBodyScroll(this.targetElement);

    setTimeout(() => {
      const mySwiper = new Swiper('.swiper-container', {
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
      });
    }, 300);

  }

}
