import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { SidenavComponent } from './core/sidenav/sidenav.component';
// import { ToolbarComponent } from './core/toolbar/toolbar.component';
// import { SubMenuComponent } from './core/sub-menu/sub-menu.component';
import { FooterComponent } from './core/footer/footer.component';
import { CoreComponent } from './core/core.component';
import { MainComponent } from './core/main/main.component';
import { HeaderComponent } from './core/header/header.component';

// import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = {
//     pinch: { enable: false },
//     rotate: { enable: false },
//     pan: { enable: false },
//   } as any;
// }
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SidenavComponent,
    // ToolbarComponent,
    AuthComponent,
    CoreComponent,
    // SubMenuComponent,
    FooterComponent,
    MainComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    // {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
