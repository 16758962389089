import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor() { }

  step = 1;
  phoneNumber: number;
  phoneNumberForm: FormGroup;
  verificationCodeForm: FormGroup;
  userDetails: FormGroup;
  selected = -1;

  ngOnInit() { }

  next() {
    this.step++;
    if (this.step === 2) {
      this.phoneNumberForm = new FormGroup({
        phoneNumber: new FormControl('', Validators.required),
      });
    }
    if (this.step === 3) {
      this.phoneNumber = this.phoneNumberForm.value.phoneNumber;
      this.verificationCodeForm = new FormGroup({
        confirmCode: new FormControl('', Validators.required),
      });
    }
    if (this.step === 4) {
      this.userDetails = new FormGroup({
        name: new FormControl(''),
        lastName: new FormControl(''),
        male: new FormControl(''),
        female: new FormControl(''),
        year: new FormControl(''),
      });
    }
  }

  back() {
    this.step--;
  }

}


